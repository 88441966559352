import './global.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Counter from './features/Counter';

function App() {
  return (
    <Router>
      <div className="App container">
        <header className="App-header">
          <h1>Virtual Counselor - B2B</h1>
        </header>
      </div>
      <Routes>
        <Route path="/" element={<h1>Home</h1>} />
        <Route path="/about" element={<h1>About</h1>} />
        <Route path="/counter" element={<Counter />} />
      </Routes>
    </Router>
  );
}

export default App;
